import axios from "axios";
import VueCookies from 'vue-cookies';

const instance = axios.create({
  // baseURL: 'http://yxt.528k.cn:10000/',
  baseURL: process.env.VUE_APP_API,
  timeout: 5000,
});

function baseRequest(url, data, method) {
  return instance({
    method: method,
    url: url,
    data: data,
    headers: {
      'Authorization': 'Bearer ' + VueCookies.get("token")
    }
  });
}

// 基本信息
export function getBase() {
  return baseRequest("api/setting/sysconfig", {}, "get");
}

// 获取内容详情
export function getInfo(id) {
  console.log("request=", window.localStorage.getItem('currentWebrtcId'))
  return baseRequest(`api/client/article/show/${id}/${window.localStorage.getItem('currentWebrtcId') || 0}`, {}, "get");
}

// 获取音乐详情
export function getMusicInfo(id) {
  console.log("request=", window.localStorage.getItem('currentWebrtcId'))
  return baseRequest(`api/client/article/show/${id}/${window.localStorage.getItem('currentWebrtcId') || 0}`, {}, "get");
}

// 获取单页详情
export function getContent(id) {
  return baseRequest(`api/article/8/${id}`, {}, "get");
}

// 获取栏目详情
export function getCategory(cate) {
  return baseRequest(`/api/article/category/${cate}`, {}, "get");
}

// 获取栏目详情2
export function getCategory2(cid,pid) {
  return baseRequest(`/api/article/category/view/${cid}/${pid}/0`, {}, "get");
}

// 相关指引
export function getPage(page, cate) {
  return baseRequest(`api/article/8?pageSize=20&pageIndex=${page}&CategoryId=${cate}`, {}, "get");
}

// 资源分布分页
export function getComNet(page) {
  return baseRequest(`api/article/4?pageSize=100&pageIndex=${page}`, {}, "get");
}

// 资源分布列表
export function getComNetList() {
  return baseRequest("api/article/view/4/0", {}, "get");
}

// 科普文章分页
export function getBook(page, cate, keyword) {
  return baseRequest(`api/article/2?pageSize=9&pageIndex=${page}&CategoryId=${cate}&Keyword=${keyword}`, {}, "get");
}

// 科普文章列表
export function getBookList() {
  return baseRequest("api/article/view/2/5", {}, "get");
}

// 服务资讯分页
export function getNews(page, cate, keyword) {
  return baseRequest(`api/article/8?pageSize=9&pageIndex=${page}&CategoryId=${cate}&Keyword=${keyword}`, {}, "get");
}

// 服务资讯列表
export function getNewsList() {
  return baseRequest("api/article/view/8/5", {}, "get");
}

// 咨询服务分页
export function getTushu(page, cate, keyword) {
  return baseRequest(`api/article/7?pageSize=9&pageIndex=${page}&CategoryId=${cate}&Keyword=${keyword}`, {}, "get");
}

// 咨询服务列表
export function getTushuList() {
  return baseRequest("api/article/view/7/5", {}, "get");
}

// 音乐分页
export function getMusicCate(page, cate, keyword) {
  return baseRequest(`api/article/3?pageSize=100&pageIndex=${page}&CategoryId=${cate}&Keyword=${keyword}`, {}, "get");
}

// 音乐列表
export function getMusicList() {
  return baseRequest("api/article/view/3/2", {}, "get");
}

// 心理健康分页
export function getXinlijiankang(page, cate, keyword) {
  return baseRequest(`api/article/5?pageSize=9&pageIndex=${page}&CategoryId=${cate}&Keyword=${keyword}`, {}, "get");
}

// 心理健康列表
export function getXinlijiankangList() {
  return baseRequest("api/article/view/5/3", {}, "get");
}

// 心理健康分页
export function getJinshenkangfu(page, cate, keyword) {
  return baseRequest(`api/article/14?pageSize=9&pageIndex=${page}&CategoryId=${cate}&Keyword=${keyword}`, {}, "get");
}

// 心理健康列表
export function getJinshenkangfuList() {
  return baseRequest("api/article/view/14/3", {}, "get");
}

// 课程菜单
export function getCourse(page, cate, keyword) {
  return baseRequest(`api/article/6?pageSize=9&pageIndex=${page}&CategoryId=${cate}&Keyword=${keyword}`, {}, "get");
}

// 课程菜单列表
export function getCourseList() {
  return baseRequest("api/article/view/6/3", {}, "get");
}

// 课程预约菜单
export function getCourseOrder(page, cate, keyword) {
  return baseRequest(`api/article/12?pageSize=9&pageIndex=${page}&CategoryId=${cate}&Keyword=${keyword}`, {}, "get");
}

// 课程预约菜单列表
export function getCourseOrderList() {
  return baseRequest("api/article/view/12/3", {}, "get");
}

// 惠民政策
export function getHuiminPolicy(page, cate, keyword) {
  return baseRequest(`api/article/13?pageSize=9&pageIndex=${page}&CategoryId=${cate}&Keyword=${keyword}`, {}, "get");
}

// 惠民政策列表
export function getHuiminPolicyList() {
  return baseRequest("api/article/view/13/3", {}, "get");
}

// 常见问题分页
export function getQA(page, cate, keyword) {
  return baseRequest(`api/article/7?pageSize=10&pageIndex=${page}&CategoryId=${cate}&Keyword=${keyword}`, {}, "get");
}

// 常见问题列表
export function getQAList() {
  return baseRequest("api/article/view/7/7", {}, "get");
}

// 活动资讯列表
export function getZixun(page, cate, keyword) {
  return baseRequest(`api/article/10?pageSize=9&pageIndex=${page}&CategoryId=${cate}&Keyword=${keyword}`, {}, "get");
}

// 活动资讯列表
export function getZixunNotice(page, cate, keyword) {
  return baseRequest(`api/article/15?pageSize=9&pageIndex=${page}&CategoryId=${cate}&Keyword=${keyword}`, {}, "get");
}

// 专家预约门诊列表
export function getPro() {
  return baseRequest("api/article/view/9/0", {}, "get");
}

// 登录
export function denglu(data) {
  return baseRequest("api/auth/login", data, "post");
}

// 注册
export function zhuce(data) {
  return baseRequest("api/account/member/register", data, "post");
}

// 获取用户信息
export function getUserInfo(modelKey) {
  return baseRequest("api/account/member?modelKey=" + modelKey, {}, "get");
}

// 微信登录地址
export function getWxUrl(data) {
  return baseRequest("api/auth/wxlogin", data, "get");
}

// 获取登录状态
export function getLoginBack(data) {
  return baseRequest("api/auth/logback?code="+data, {}, "get");
}

export function logout() {
  return baseRequest("api/auth/logout", "get");
}

// webrtc参数
export function getWebrtcParams(id) {
  return baseRequest("api/article/view/11/" + id, {}, "get");
}