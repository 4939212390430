<template>
  <div class="app">
    <div class="left-bg">
      <div class="picbox">
        <div class="pic" style="background-image: url(/img/left_bg.png)"></div>
      </div>
    </div>
    <div class="right-bg">
      <div class="picbox">
        <div class="pic" style="background-image: url(/img/right_bg.png)"></div>
      </div>
    </div>
    <router-view style="position: relative; z-index: 3;" />
    <div class="right-menu" v-if="rightShow">
      <div class="item" @click="goto('/')">
        <div class="picbox">
          <div class="pic" style="background-image: url(/img/right_index.png)"></div>
        </div>
      </div>
      <div class="item" @click="this.$router.go(-1);">
        <div class="picbox">
          <div class="pic" style="background-image: url(/img/right_back.png)"></div>
        </div>
      </div>
      <el-backtop>
        <div class="item">
          <div class="picbox">
            <div class="pic" style="background-image: url(/img/right_totop.png)"></div>
          </div>
        </div>
      </el-backtop>
    </div>
  </div>
</template>

<script>
import { ElBacktop } from 'element-plus'

export default {
  components: {
    ElBacktop
  },
  data() {
    return {
      screensaverCount: 0,
      rightShow: true,
      music: false
    }
  },
  provide() {
    return {
      musicOn: this.musicOn,
      musicOff: this.musicOff
    }
  },
  mounted() {
    // 监听鼠标
    document.onmousemove = () => {
      this.screensaverCount = 0
    }
    // 监听键盘
    document.onkeydown = () => {
      this.screensaverCount = 0
    }
    // 监听Scroll
    document.onscroll = () => {
      this.screensaverCount = 0
    }
    this.setTimer();
  },
  // 清除定时器
  beforeUnmount() {
    this.clearTimer()
  },
  methods: {
    showRight(){
      this.rightShow=true;
    },
    hideRight(){
      this.rightShow=false;
    },
    goto(adress) {
      this.$router.push(adress);
    },
    clearTimer() {
      clearInterval(window.screensaverTimer)
      window.Timer = null
    },
    setTimer() {
      this.screensaverCount = 0
      if (!window.screensaverTimer) {
        window.screensaverTimer = window.setInterval(this.screensaver, 1000)
      }
    },
    musicOn() {
      this.music = true;
    },
    musicOff() {
      this.music = false;
    },
    async screensaver() {      
      this.screensaverCount++;
      //5分钟
      if (this.screensaverCount > 5 * 60 && !this.music) {
        this.$router.replace('/screensaver');
      }
    }
  }
}
</script>

<style lang="scss">
.app {
  position: relative;
  min-width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  user-select: none;

  .left-bg,
  .right-bg {
    @include sys-picbox(1000px, 840px);
    position: absolute;
  }

  .left-bg {
    top: 0;
    left: 0;
  }

  .right-bg {
    right: 0;
    bottom: 0;
  }

  .right-menu {
    position: fixed;
    bottom: 46%;
    right: 140px;
    z-index: 999;

    .item {
      @include sys-picbox(60px, 60px);
      margin-bottom: 20px;
      cursor: pointer;
    }

    .el-backtop {
      right: auto !important;
      bottom: auto !important;
      width: auto;
      height: auto;
      display: block;
      --el-backtop-bg-color: none;
      --el-backtop-hover-bg-color: none;
      background: none;
      box-shadow: none;
    }
  }

  .right-qrcode {
    position: fixed;
    bottom: 60px;
    right: 90px;
    @include sys-picbox(130px, 191px);

    img {
      width: 94px;
      height: 94px;
      position: absolute;
      top: 40px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.fenye {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;

  .el-pager li,
  .btn-prev,
  .btn-next {
    padding: 20px !important;
  }
}

.empty {
  width: 100%;
  text-align: center;
  font-size: 28px;
  margin: 60px 0;
}

.ql-video{
  width: 100%;
  height: 600px;  
}

video{
  width: 100%;
  height: 100%;
}
</style>
