import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/newuser',
    name: 'NewUser',
    component: () => import('../views/NewUser.vue')
  },
  {
    path: '/',
    name: 'Default',
    component: () => import('../views/Screensaver.vue')
  },
  {
    path: '/index',
    name: 'Index',
    component: () => import('../views/Index.vue')
  },
  {
    path: '/mentalitycate',
    name: 'MentalityCate',
    component: () => import('../views/MentalityCate.vue'),
    children: [
      {
        path: '/mentality/tushu',
        name: '图文科普',
        component: () => import('../views/mentality/TushuList.vue')
      },
      {
        path: '/mentality/zixun',
        name: '咨询服务',
        component: () => import('../views/mentality/ZixunList.vue')
      },
      {
        path: '/mentality/courses',
        name: '课程菜单',
        component: () => import('../views/mentality/Courses.vue')
      },
      {
        path: '/mentality/xinlijiankang',
        name: '心理健康',
        component: () => import('../views/mentality/XinlijiankangList.vue')
      },
      // {
      //   path: '/mentality/compositionlist',
      //   name: 'CompositionList',
      //   component: () => import('../views/mentality/CompositionList.vue')
      // }
    ]
  },
  {
    path: '/newsList',
    name: 'NewsList',
    component: () => import('../views/NewsList.vue'),
    children: [
      {
        path: '/event',
        name: '活动资讯',
        component: () => import('../views/mentality/Event.vue')
      },
      {
        path: '/eventNotice',
        name: '活动预告',
        component: () => import('../views/mentality/EventNotice.vue')
      },
      {
        path: '/mentalNews',
        name: '心理资讯',
        component: () => import('../views/mentality/MentalNews.vue')
      },
      {
        path: '/mentality/coursesOrder',
        name: '课程预约',
        component: () => import('../views/mentality/CoursesOrder.vue')
      },
      {
        path: '/mentality/huiminPolicy',
        name: '惠民政策',
        component: () => import('../views/mentality/HuiminPolicy.vue')
      },
      {
        path: '/mentality/jinshenkangfu',
        name: '精神康复',
        component: () => import('../views/mentality/JinshenkangfuList.vue')
      },
    ]
  },
  {
    path: '/compositionshow',
    name: 'CompositionShow',
    component: () => import('../views/CompositionShow.vue')
  },
  {
    path: '/appointment',
    name: 'Appointment',
    component: () => import('../views/Appointment.vue')
  },
  {
    path: '/screensaver',
    name: 'Screensaver',
    component: () => import('../views/Screensaver.vue')
  },
  {
    path: '/contact',
    name: '资源分布',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/musicplayer',
    name: 'MusicPlayer',
    component: () => import('../views/MusicPlayer.vue'),
  },
  {
    path: '/returnbefore',
    name: 'ReturnBefore',
    component: () => import('../views/ReturnBefore.vue'),
  }
]

// const router = createRouter({
//   history: createWebHashHistory(),
//   routes
// })

// export default router


const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  if (isChunkLoadFailed) {
    // 用路由的replace方法，并没有相当于F5刷新页面，失败的js文件并没有从新请求，会导致一直尝试replace页面导致死循环，而用 location.reload 方法，相当于触发F5刷新页面，虽然用户体验上来说会有刷新加载察觉，但不会导致页面卡死及死循环，从而曲线救国解决该问题
    location.reload();
    // const targetPath = $router.history.pending.fullPath;
    // $router.replace(targetPath);
  }

});

export default router
